export type Breakpoints = string[]

export type MediaQueries = {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
    xxxl: string
    nav: string
}

export type Spacing = number[]

export type Radii = {
    small: string
    default: string
    card: string
    circle: string
}

export type Shadows = {
    level1: string
    active: string
    success: string
    warning: string
    focus: string
    inset: string
}

export type Gradients = {
    bubblegum: string
    inverseBubblegum: string
    cardHeader: string
    blue: string
    violet: string
    violetAlt: string
    gold: string
}

export type Colors = {
    primary: string
    primaryBright: string
    primaryDark: string
    secondary: string
    tertiary: string
    success: string
    failure: string
    warning: string
    cardBorder: string
    contrast: string
    dropdown: string
    dropdownDeep: string
    invertedContrast: string
    input: string
    inputSecondary: string
    background: string
    backgroundDisabled: string
    backgroundAlt: string
    text: string
    textDisabled: string
    textSubtle: string
    disabled: string

    // Gradients
    gradients: Gradients

    // Additional colors
    binance: string
    overlay: string
}

export type ZIndices = {
    dropdown: number
    modal: number
}

export const breakpointMap: { [key: string]: number } = {
    xs: 396,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    xxxl: 1920,
}

const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`)

export const minMediaQueries: MediaQueries = {
    xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
    sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
    md: `@media screen and (min-width: ${breakpointMap.md}px)`,
    lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
    xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
    xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
    xxxl: `@media screen and (min-width: ${breakpointMap.xxxl}px)`,
    nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
}

export const maxMediaQueries: MediaQueries = {
    xs: `@media screen and (max-width: ${breakpointMap.xs}px)`,
    sm: `@media screen and (max-width: ${breakpointMap.sm}px)`,
    md: `@media screen and (max-width: ${breakpointMap.md}px)`,
    lg: `@media screen and (max-width: ${breakpointMap.lg}px)`,
    xl: `@media screen and (max-width: ${breakpointMap.xl}px)`,
    xxl: `@media screen and (max-width: ${breakpointMap.xxl}px)`,
    xxxl: `@media screen and (max-width: ${breakpointMap.xxxl}px)`,
    nav: `@media screen and (max-width: ${breakpointMap.lg}px)`,
}

export const shadows = {
    level1: '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)',
    active: '0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)',
    success: '0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)',
    warning: '0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)',
    focus: '0px 0px 0px 1px #7645D9, 0px 0px 0px 4px rgba(118, 69, 217, 0.6)',
    inset: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
}

const spacing: Spacing = [0, 4, 8, 16, 24, 32, 48, 64]

const radii = {
    small: '4px',
    default: '16px',
    card: '24px',
    circle: '50%',
}

const zIndices = {
    dropdown: 10,
    modal: 100,
}

export default {
    siteWidth: 1200,
    breakpoints,
    spacing,
    shadows,
    radii,
    zIndices,
}
