import { useTranslation } from '@echodex/localization'
import { Swap } from '@echodex/uikit'
import { EXCHANGE_HELP_URLS } from 'config/constants'

const Page: React.FC<
    React.PropsWithChildren<{
        removePadding?: boolean
        hideFooterOnDesktop?: boolean
        noMinHeight?: boolean
        helpUrl?: string
    }>
> = ({
    children,
    removePadding = false,
    hideFooterOnDesktop = false,
    noMinHeight = false,
    helpUrl = EXCHANGE_HELP_URLS,
    ...props
}) => {
    const { t } = useTranslation()
    const isBSC = false
    const externalText = isBSC ? t('Bridge assets to BNB Chain') : ''
    const externalLinkUrl = isBSC ? 'https://bridge.echodex.io/' : ''

    return (
        <>
            <Swap.Page
                removePadding={removePadding}
                noMinHeight={noMinHeight}
                hideFooterOnDesktop={hideFooterOnDesktop}
                helpUrl={helpUrl}
                externalText={externalText}
                externalLinkUrl={externalLinkUrl}
                {...props}
            >
                {children}
            </Swap.Page>
        </>
    )
}

export default Page
