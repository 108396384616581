import { Currency } from '@echodex/sdk'
import { TokenLogo } from '@echodex/uikit'
import { useMemo } from 'react'
import { WrappedTokenInfo } from '@echodex/token-lists'
import styled from 'styled-components'
import { useHttpLocations } from '@echodex/hooks'

const StyledLogo = styled(TokenLogo)<{ size: string }>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: 50%;
`

interface LogoProps {
    currency?: Currency
    size?: string
    style?: React.CSSProperties
}

export function FiatLogo({ currency, size = '24px', style }: LogoProps) {
    return (
        <StyledLogo
            size={size}
            srcs={[`/images/currencies/${currency?.symbol?.toLowerCase()}.png`]}
            width={size}
            style={style}
        />
    )
}

export default function CurrencyLogo({ currency, size = '24px', style }: LogoProps) {
    const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

    const srcs: string[] = useMemo(() => {
        if(!currency) return []
        if (currency?.isNative) return ['/images/coins/eth.png']
        return [...uriLocations, `/images/coins/${currency.symbol.toLowerCase()}.png`]
    }, [currency, uriLocations])

    if(currency?.symbol === "WETH" || currency?.symbol === "ETH"){
        return <StyledLogo srcs={["/images/coins/eth.png"]} size={size} style={style} />
    }

    if(currency?.symbol === "xECP"){
        return <StyledLogo srcs={["/images/coins/ecp.png"]} size={size} style={style} />
    }

    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
