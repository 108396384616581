import { ChainId } from '@echodex/sdk'

// Revalidate interval in milliseconds
export const POOLS_FAST_REVALIDATE = {
    [ChainId.LINEA_TESTNET]: 20_000,
    [ChainId.LINEA_MAINNET]: 20_000,
} as const satisfies Record<ChainId, number>

// Revalidate interval in milliseconds
export const POOLS_NORMAL_REVALIDATE = {
    [ChainId.LINEA_TESTNET]: 20_000,
    [ChainId.LINEA_MAINNET]: 20_000,
} as const satisfies Record<ChainId, number>

export const POOLS_SLOW_REVALIDATE = {
    [ChainId.LINEA_TESTNET]: 30_000,
    [ChainId.LINEA_MAINNET]: 30_000,
} as const satisfies Record<ChainId, number>
