import { useTranslation } from '@echodex/localization'
import {
    Flex,
    InjectedModalProps,
    Modal,
    ExpertModal,
    QuestionHelper,
    Text,
    Toggle,
    Button,
    ModalV2,
    PreTitle,
    AutoColumn,
    Message,
    MessageText,
    NotificationDot,
    ButtonProps,
    Checkbox,
    AutoRow,
    RowFixed,
} from '@echodex/uikit'
import { ReactNode, useCallback, useState } from 'react'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useExpertMode, useUserSingleHopOnly, useUserExpertModeAcknowledgement } from '@echodex/utils/user'
import { useSubgraphHealthIndicatorManager, useUserUsernameVisibility } from 'state/user/hooks'
import {
    useOnlyOneAMMSourceEnabled,
    useUserSplitRouteEnable,
    useUserV2SwapEnable,
    useUserV3SwapEnable,
    useRoutingSettingChanged,
} from 'state/user/smartRouter'
import { AtomBox } from '@echodex/ui'
import styled from 'styled-components'
import TransactionSettings from './TransactionSettings'
import { SettingsMode } from './types'

const ScrollableContainer = styled(Flex)`
    flex-direction: column;
    height: auto;
    ${({ theme }) => theme.mediaQueries.xs} {
        max-height: 90vh;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        max-height: none;
    }
`

export const withCustomOnDismiss =
    (Component) =>
    ({
        onDismiss,
        customOnDismiss,
        mode,
        ...props
    }: {
        onDismiss?: () => void
        customOnDismiss: () => void
        mode: SettingsMode
    }) => {
        const handleDismiss = useCallback(() => {
            onDismiss?.()
            if (customOnDismiss) {
                customOnDismiss()
            }
        }, [customOnDismiss, onDismiss])

        return <Component {...props} mode={mode} onDismiss={handleDismiss} />
    }

const SettingsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss, mode }) => {
    const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
    const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgement()
    const [expertMode, setExpertMode] = useExpertMode()
    const [subgraphHealth, setSubgraphHealth] = useSubgraphHealthIndicatorManager()
    const [userUsernameVisibility, setUserUsernameVisibility] = useUserUsernameVisibility()
    const { onChangeRecipient } = useSwapActionHandlers()

    const { t } = useTranslation()
    // const { isDark, setTheme } = useTheme()

    if (showConfirmExpertModal) {
        return (
            <ExpertModal
                setShowConfirmExpertModal={setShowConfirmExpertModal}
                onDismiss={onDismiss}
                toggleExpertMode={() => setExpertMode((s) => !s)}
                setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
            />
        )
    }

    const handleExpertModeToggle = () => {
        if (expertMode || !showExpertModeAcknowledgement) {
            onChangeRecipient(null)
            setExpertMode((s) => !s)
        } else {
            setShowConfirmExpertModal(true)
        }
    }

    return (
        <Modal title={t('Settings')} headerBackground="gradientCardHeader" onDismiss={onDismiss}>
            <ScrollableContainer>
                {mode === SettingsMode.GLOBAL && (
                    <>
                        <Flex pb="24px" flexDirection="column">
                            <PreTitle mb="24px">{t('Global')}</PreTitle>
                            <Flex justifyContent="space-between" mb="24px">
                                <Text>{t('Dark mode')}</Text>
                                {/* <ThemeSwitcher
                                    isDark={isDark}
                                    toggleTheme={() => setTheme(isDark ? 'light' : 'dark')}
                                /> */}
                            </Flex>
                            <Flex justifyContent="space-between" alignItems="center" mb="24px">
                                <Flex alignItems="center">
                                    <Text>{t('Subgraph Health Indicator')}</Text>
                                    <QuestionHelper
                                        text={t(
                                            'Turn on subgraph health indicator all the time. Default is to show the indicator only when the network is delayed',
                                        )}
                                        placement="top"
                                        ml="4px"
                                    />
                                </Flex>
                                <Toggle
                                    id="toggle-subgraph-health-button"
                                    checked={subgraphHealth}
                                    scale="md"
                                    onChange={() => {
                                        setSubgraphHealth(!subgraphHealth)
                                    }}
                                />
                            </Flex>
                            <Flex justifyContent="space-between" alignItems="center" mb="24px">
                                <Flex alignItems="center">
                                    <Text>{t('Show username')}</Text>
                                    <QuestionHelper
                                        text={t('Shows username of wallet instead of bunnies')}
                                        placement="top"
                                        ml="4px"
                                    />
                                </Flex>
                                <Toggle
                                    id="toggle-username-visibility"
                                    checked={userUsernameVisibility}
                                    scale="md"
                                    onChange={() => {
                                        setUserUsernameVisibility(!userUsernameVisibility)
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </>
                )}
                {mode === SettingsMode.SWAP_LIQUIDITY && (
                    <>
                        <Flex pt="3px" flexDirection="column">
                            <PreTitle>{t('Swaps & Liquidity')}</PreTitle>
                            <TransactionSettings />
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center" mb="24px">
                            <Flex alignItems="center">
                                <Text>{t('Expert Mode')}</Text>
                                <QuestionHelper
                                    text={t(
                                        'Bypasses confirmation modals and allows high slippage trades. Use at your own risk.',
                                    )}
                                    placement="top"
                                    ml="4px"
                                />
                            </Flex>
                            <Toggle
                                id="toggle-expert-mode-button"
                                scale="md"
                                checked={expertMode}
                                onChange={handleExpertModeToggle}
                            />
                        </Flex>
                        <RoutingSettingsButton />
                    </>
                )}
            </ScrollableContainer>
        </Modal>
    )
}

export default SettingsModal

export function RoutingSettingsButton({
    children,
    showRedDot = true,
    buttonProps,
}: {
    children?: ReactNode
    showRedDot?: boolean
    buttonProps?: ButtonProps
}) {
    const [show, setShow] = useState(false)
    const { t } = useTranslation()
    const [isRoutingSettingChange] = useRoutingSettingChanged()
    return (
        <>
            <AtomBox textAlign="center">
                <NotificationDot show={isRoutingSettingChange && showRedDot}>
                    <Button variant="text" onClick={() => setShow(true)} scale="sm" {...buttonProps}>
                        {children || t('Customize Routing')}
                    </Button>
                </NotificationDot>
            </AtomBox>
            <ModalV2 isOpen={show} onDismiss={() => setShow(false)} closeOnOverlayClick>
                <RoutingSettings />
            </ModalV2>
        </>
    )
}

function RoutingSettings() {
    const { t } = useTranslation()
    const [v2Enable, setV2Enable] = useUserV2SwapEnable()
    const [v3Enable, setV3Enable] = useUserV3SwapEnable()
    const [split, setSplit] = useUserSplitRouteEnable()
    const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
    const onlyOneAMMSourceEnabled = useOnlyOneAMMSourceEnabled()
    const [isRoutingSettingChange, reset] = useRoutingSettingChanged()

    return (
        <Modal
            title={t('Customize Routing')}
            headerRightSlot={
                isRoutingSettingChange && (
                    <Button variant="text" scale="sm" onClick={reset}>
                        {t('Reset')}
                    </Button>
                )
            }
        >
            <AutoColumn
                width={{
                    xs: '100%',
                    md: 'screenSm',
                }}
                gap="16px"
            >
                <AtomBox>
                    <PreTitle mb="24px">{t('Liquidity source')}</PreTitle>
                    <Flex justifyContent="space-between" alignItems="center" mb="24px">
                        <Flex alignItems="center">
                            <Text>Echodex V3</Text>
                            <QuestionHelper
                                text={
                                    <Flex>
                                        <Text mr="5px">
                                            {t(
                                                'V3 offers concentrated liquidity to provide deeper liquidity for traders with the same amount of capital, offering lower slippage and more flexible trading fee tiers.',
                                            )}
                                        </Text>
                                    </Flex>
                                }
                                placement="top"
                                ml="4px"
                            />
                        </Flex>
                        <Toggle
                            disabled={v3Enable && onlyOneAMMSourceEnabled}
                            scale="md"
                            checked={v3Enable}
                            onChange={() => setV3Enable((s) => !s)}
                        />
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mb="24px">
                        <Flex alignItems="center">
                            <Text>Echodex V2</Text>
                            <QuestionHelper
                                text={
                                    <Flex flexDirection="column">
                                        <Text mr="5px">
                                            {t(
                                                'The previous V2 exchange is where a number of iconic, popular assets are traded.',
                                            )}
                                        </Text>
                                        <Text mr="5px" mt="1em">
                                            {t('Recommend leaving this on to ensure backward compatibility.')}
                                        </Text>
                                    </Flex>
                                }
                                placement="top"
                                ml="4px"
                            />
                        </Flex>
                        <Toggle
                            disabled={v2Enable && onlyOneAMMSourceEnabled}
                            scale="md"
                            checked={v2Enable}
                            onChange={() => setV2Enable((s) => !s)}
                        />
                    </Flex>
                    {onlyOneAMMSourceEnabled && (
                        <Message variant="warning">
                            <MessageText>
                                {t('At least one AMM liquidity source has to be enabled to support normal trading.')}
                            </MessageText>
                        </Message>
                    )}
                </AtomBox>
                <AtomBox>
                    <PreTitle mb="24px">{t('Routing preference')}</PreTitle>
                    <AutoRow alignItems="center" mb="24px">
                        <RowFixed as="label" gap="16px">
                            <Checkbox
                                id="toggle-disable-multihop-button"
                                checked={!singleHopOnly}
                                scale="sm"
                                onChange={() => {
                                    setSingleHopOnly((s) => !s)
                                }}
                            />
                            <Text>{t('Allow Multihops')}</Text>
                        </RowFixed>
                        <QuestionHelper
                            text={
                                <Flex flexDirection="column">
                                    <Text mr="5px">
                                        {t(
                                            'Multihops enables token swaps through multiple hops between several pools to achieve the best deal.',
                                        )}
                                    </Text>
                                    <Text mr="5px" mt="1em">
                                        {t(
                                            'Turning this off will only allow direct swap, which may cause higher slippage or even fund loss.',
                                        )}
                                    </Text>
                                </Flex>
                            }
                            placement="top"
                            ml="4px"
                        />
                    </AutoRow>
                    <AutoRow alignItems="center" mb="24px">
                        <RowFixed alignItems="center" as="label" gap="16px">
                            <Checkbox
                                id="toggle-disable-multihop-button"
                                checked={split}
                                scale="sm"
                                onChange={() => {
                                    setSplit((s) => !s)
                                }}
                            />
                            <Text>{t('Allow Split Routing')}</Text>
                        </RowFixed>
                        <QuestionHelper
                            text={
                                <Flex flexDirection="column">
                                    <Text mr="5px">
                                        {t(
                                            'Split routing enables token swaps to be broken into multiple routes to achieve the best deal.',
                                        )}
                                    </Text>
                                    <Text mr="5px" mt="1em">
                                        {t(
                                            'Turning this off will only allow a single route, which may result in low efficiency or higher slippage.',
                                        )}
                                    </Text>
                                </Flex>
                            }
                            placement="top"
                            ml="4px"
                        />
                    </AutoRow>
                </AtomBox>
            </AutoColumn>
        </Modal>
    )
}
