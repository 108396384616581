import styled from 'styled-components'
import { Card, CardProps } from '@echodex/uikit'
import { maxMediaQueries } from 'utils/theme'

export const BodyWrapper = styled(Card)`
    border-radius: 12px;
    width: 458px;
    z-index: 1;
    margin-right: 10px;
    ${maxMediaQueries.sm} {
        max-width: 458px;

        width: 100%;
    }
`

export const BodyWrapperDetail = styled(Card)`
    border-radius: 12px;
    width: 400px;
    z-index: 0;
    margin-left: -410px;
    height: 100%;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;
    ${maxMediaQueries.lg} {
        max-width: 458px;
        width: 100%;
        height: auto;
    }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...cardProps }: { children: React.ReactNode } & CardProps) {
    return <BodyWrapper {...cardProps}>{children}</BodyWrapper>
}

export function AppBodyDetail({ children, ...cardProps }: { children: React.ReactNode } & CardProps) {
    return <BodyWrapperDetail {...cardProps}>{children}</BodyWrapperDetail>
}
